<template v-if="item">
  <div v-if="!testIgnore" :class="{ 'text-right': $vuetify.rtl }">
    <a
      href="#"
      class="btn btn-light-facebook btn-sm px-4 custom-btn text-no-wrap"
      @click="toggleModal"
      >{{ buttonText }}</a
    >
    <div>
      <v-dialog
        v-model="dialog"
        persistent
        transition="dialog-bottom-transition"
        max-width="850"
      >
        <!--begin::Modal dialog-->
        <div class="bg-white poppins">
          <!--begin::Modal header-->
          <div class="modal-header align-center">
            <h3 class="mb-0 font-weight-bolder">{{ header.text }}</h3>
            <!--begin::Close-->
            <div
              class="btn btn-sm btn-icon btn-active-light-info"
              @click="dialog = false"
            >
              <span class="svg-icon">
                <v-icon size="22">mdi-close</v-icon>
              </span>
            </div>
            <!--end::Close-->
          </div>
          <!--begin::Modal header-->
          <!--begin::Modal body-->
          <div
            class="modal-body d-flex flex-column h-550px scroll-y mx-5 mx-xl-15 py-7"
          >
            <!--begin::Body-->
            <json-view :data="jsonData"></json-view>
            <!--end::Body-->
          </div>
          <!--end::Modal body-->
        </div>
        <!--end::Modal dialog-->
      </v-dialog>
    </div>
  </div>
  <span v-else class="badge font-size-sm my-1 py-2 px-4 badge-light-danger">
    {{ $t("test_ignore") }}
  </span>
</template>

<script>
export default {
  name: "ShowData",
  props: ["item", "header"],
  data: () => ({
    dialog: false,
    testIgnore: false,
  }),
  computed: {
    jsonData: function () {
      // TODO: translation effected condition
      if (this.header.type !== "object") {
        if (this.item.data === "Test Ignore") {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.testIgnore = true;
          return null;
        }
        if (this.item.data) {
          return JSON.parse(this.item.data);
        } else return null;
      } else {
        return this.item;
      }
    },
    buttonText: function () {
      let text = this.$t("view");
      if (this.item?.length === 0) text = this.$t("no_data");
      return text;
    },
  },
  methods: {
    toggleModal() {
      if (this.buttonText !== "No data") this.dialog = !this.dialog;
    },
  },
};
</script>
